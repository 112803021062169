// src/components/DeckContext.js
import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { creationTempCard } from './creationTempCard';

// Create a context for deck-related state and functions
const DeckContext = createContext();

// Custom hook to use the DeckContext
export const useDeckContext = () => {
  const context = useContext(DeckContext);
  if (!context) {
    throw new Error('useDeckContext must be used within a DeckProvider');
  }
  return context;
};

export const DeckProvider = ({ children }) => {
  // State declarations
  const [decks, setDecks] = useState([]);                                           // Stores all decks
  const [currentDeck, setCurrentDeck] = useState(null);                             // The currently active deck
  const [isLoading, setIsLoading] = useState(true);                                 // Loading state for async operations
  const [currentCardIndex, setCurrentCardIndex] = useState(0);                      // Index of the current card
  const [tempCard, setTempCard] = useState(creationTempCard);                       // Temporary card for creation/editing
  const [userStudying, setUserStudying] = useState(false);                          // Whether the user is in study mode
  const [studySessionStartTime, setStudySessionStartTime] = useState(null);         // State to safely detect when startFullDeckStudySession() is called
  const [showFront, setShowFront] = useState(true);                                 // Whether to show the front of the card
  const [renderedLatex, setRenderedLatex] = useState({ front: null, back: null });  // Rendered LaTeX content
  const [rawLatex, setRawLatex] = useState({ front: null, back: null });            // Raw LaTeX content
  const [editedFrontLatexContent, setEditedFrontLatexContent] = useState(null);     // Edited front LaTeX content
  const [editedBackLatexContent, setEditedBackLatexContent] = useState(null);       // Edited back LaTeX content
  const [errorMessage, setErrorMessage] = useState(null);                           // Error messages for user feedback

  // Get authentication functions from Auth0
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  // Function to make authenticated API requests
  // This encapsulates the logic for adding the auth token to requests
  const makeAuthenticatedRequest = useCallback(async (url, method, body) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const options = { method, headers };
      if (method !== 'GET' && method !== 'HEAD' && body) {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }
      return response.json();
    } catch (error) {
      console.error('Error in makeAuthenticatedRequest:', error);
      throw error;
    }
  }, [getAccessTokenSilently]);

  // Function to fetch all decks
  const fetchDecks = useCallback(async () => {
    if (!isAuthenticated) {
      setDecks([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      const fetchedDecks = await makeAuthenticatedRequest('/api/decks', 'GET');
      setDecks(fetchedDecks);
    } catch (error) {
      console.error('Error fetching decks:', error);
      setDecks([]);
    } finally {
      setIsLoading(false);
    }
  }, [isAuthenticated, makeAuthenticatedRequest]);

  // Fetch decks when the component mounts or auth status changes
  useEffect(() => {
    fetchDecks();
  }, [fetchDecks]);

  // Function to add a new deck
  const addDeck = useCallback(async (name) => {
    try {
      const newDeck = await makeAuthenticatedRequest('/api/decks', 'POST', { name });
      setDecks(prevDecks => [...prevDecks, newDeck]);
      return newDeck;
    } catch (error) {
      console.error('Error adding deck:', error);
      throw error;
    }
  }, [makeAuthenticatedRequest]);

  // Function to update an existing deck
  const updateDeck = useCallback(async (deckId, updatedData) => {
    try {
      const updatedDeck = await makeAuthenticatedRequest(`/api/decks/${deckId}`, 'PUT', updatedData);
      setDecks(prevDecks => prevDecks.map(deck => deck._id === deckId ? updatedDeck : deck));
      if (currentDeck && currentDeck._id === deckId) {
        setCurrentDeck(updatedDeck);
      }
      return updatedDeck;
    } catch (error) {
      console.error('Error updating deck:', error);
      throw error;
    }
  }, [makeAuthenticatedRequest, currentDeck]);

  // Function to delete a deck
  const deleteDeck = useCallback(async (deckId) => {
    try {
      await makeAuthenticatedRequest(`/api/decks/${deckId}`, 'DELETE');
      setDecks(prevDecks => prevDecks.filter(deck => deck._id !== deckId));
      if (currentDeck && currentDeck._id === deckId) {
        setCurrentDeck(null);
      }
    } catch (error) {
      console.error('Error deleting deck:', error);
      throw error;
    }
  }, [makeAuthenticatedRequest, currentDeck]);

  // Function to add a new card to a deck
  const addCard = useCallback(async (deckId, cardData) => {
    try {
      const updatedDeck = await makeAuthenticatedRequest(`/api/decks/${deckId}/cards`, 'POST', cardData);
      setDecks(prevDecks => prevDecks.map(deck => deck._id === deckId ? updatedDeck : deck));
      if (currentDeck && currentDeck._id === deckId) {
        setCurrentDeck(updatedDeck);
      }
      return updatedDeck;
    } catch (error) {
      console.error('Error adding card:', error);
      throw error;
    }
  }, [makeAuthenticatedRequest, currentDeck]);

  // Function to update an existing card
  const updateCard = useCallback(async (deckId, cardId, cardData) => {
    try {
      const updatedDeck = await makeAuthenticatedRequest(`/api/decks/${deckId}/cards/${cardId}`, 'PUT', cardData);
      setDecks(prevDecks => prevDecks.map(deck => deck._id === deckId ? updatedDeck : deck));
      if (currentDeck && currentDeck._id === deckId) {
        setCurrentDeck(updatedDeck);
      }
      return updatedDeck;
    } catch (error) {
      console.error('Error updating card:', error);
      throw error;
    }
  }, [makeAuthenticatedRequest, currentDeck]);

  // Function to delete a card from a deck
  const deleteCard = useCallback(async (deckId, cardId) => {
    try {
      const updatedDeck = await makeAuthenticatedRequest(`/api/decks/${deckId}/cards/${cardId}`, 'DELETE');
      setDecks(prevDecks => prevDecks.map(deck => deck._id === deckId ? updatedDeck : deck));
      if (currentDeck && currentDeck._id === deckId) {
        setCurrentDeck(updatedDeck);
      }
      return updatedDeck;
    } catch (error) {
      console.error('Error deleting card:', error);
      throw error;
    }
  }, [makeAuthenticatedRequest, currentDeck]);

  // Function to handle starting or ending a study session normally
  const handleStudySessionToggle = useCallback(async (deckId, startSession) => {
    try {
      const endpoint = startSession ? 'start-session' : 'end-session';
      await makeAuthenticatedRequest(`/api/decks/${deckId}/${endpoint}`, 'POST');
      await fetchDecks();
      if (startSession) setCurrentCardIndex(0);
      setUserStudying(startSession);
    } catch (error) {
      console.error(`Error ${startSession ? 'starting' : 'ending'} study session:`, error);
      setErrorMessage(`Failed to ${startSession ? 'start' : 'end'} study session. Please try again.`);
    }
  }, [makeAuthenticatedRequest, fetchDecks, setCurrentCardIndex, setUserStudying, setErrorMessage]);

  // Function to start a study session with all cards regardless of next repetition date
  const startFullDeckStudySession = useCallback(async (deckId) => {
    try {
      await makeAuthenticatedRequest(`/api/decks/${deckId}/start-fd-session`, 'POST');
      await fetchDecks();
      setCurrentCardIndex(0);
      setStudySessionStartTime(Date.now());
      setUserStudying(true);
    } catch (error) {
      console.error(`Error starting full-deck study session:`, error);
      setErrorMessage('Failed to start full deck study session. Please try again.');
    }
  }, [makeAuthenticatedRequest, fetchDecks, setCurrentCardIndex, setUserStudying, setErrorMessage]);

  // Function to update card repetition data
  const updateCardRepetition = useCallback(async (deckId, cardId, responseQuality) => {
    try {
      const updatedDeck = await makeAuthenticatedRequest(`/api/decks/${deckId}/cards/${cardId}/repetition`, 'PUT', { responseQuality });
      setDecks(prevDecks => prevDecks.map(deck => deck._id === deckId ? updatedDeck : deck));
      if (currentDeck && currentDeck._id === deckId) {
        setCurrentDeck(updatedDeck);
      }
      return updatedDeck;
    } catch (error) {
      console.error('Error updating card repetition:', error);
      throw error;
    }
  }, [makeAuthenticatedRequest, currentDeck]);

  // Function to render LaTeX content
  const renderLatex = useCallback(async (latex) => {
    try {
      const response = await makeAuthenticatedRequest('/api/decks/render-latex', 'POST', { latexContent: latex });
      if (response) {
        setRenderedLatex(response.renderedLatex);
      }
    } catch (error) {
      console.error('Error rendering LaTeX:', error);
      throw error;
    }
  }, [makeAuthenticatedRequest]);

  // Function to calculate the next repetition due date for a card
  const getNextRepetitionDueDate = useCallback((card) => {
    if (!card || !card.lastRepetition || !card.nextInterval) {
      return -1;
    }
    const { lastRepetition, nextInterval } = card;
    const dueDate = new Date(Date.parse(lastRepetition) + nextInterval * 24 * 60 * 60 * 1000);
    const now = Date.now();
    return ((dueDate - now) / 24 / 60 / 60 / 1000).toFixed(2);
  }, []);

  // Memoized value for combined cards (including temp card when not studying)
  const combinedCards = useMemo(() => {
    if (!currentDeck) return [creationTempCard];

    let cardsToShow = currentDeck.cards || [];

    if (userStudying && currentDeck.studySession) {
      cardsToShow = (currentDeck.studySession.cardsToReview || []).map(cardId =>
        currentDeck.cards.find(card => card._id.toString() === cardId.toString())
      ).filter(Boolean); // Remove any undefined values
    }

    const sortedCards = cardsToShow.slice().sort((a, b) => {
      const timerA = getNextRepetitionDueDate(a);
      const timerB = getNextRepetitionDueDate(b);

      if ((timerA < 0 && timerB < 0) || (timerA >= 0 && timerB >= 0)) return timerA - timerB;
      if (timerA < 0 && timerB >= 0) return -1;
      if (timerA >= 0 && timerB < 0) return 1;
      return 0;
    });

    return userStudying ? sortedCards : [tempCard, ...sortedCards];
  }, [currentDeck, userStudying, tempCard, getNextRepetitionDueDate]);

  // The value object that will be provided to the context consumers
  const value = {
    // State values
    decks,                      // Array of all decks
    currentDeck,                // Currently selected deck
    isLoading,                  // Loading state for async operations
    currentCardIndex,           // Index of the current card being viewed/edited
    tempCard,                   // Temporary card for creation/editing
    userStudying,               // Boolean indicating if user is in study mode
    studySessionStartTime,
    showFront,                  // Boolean to toggle front/back of card
    renderedLatex,              // Object containing rendered LaTeX for front and back
    rawLatex,                   // Object containing raw LaTeX for front and back
    editedFrontLatexContent,    // Edited content for front of card
    editedBackLatexContent,     // Edited content for back of card
    errorMessage,               // Error message to display to user
    combinedCards,              // Memoized array of cards (including temp card when not studying)

    // State setters
    setCurrentDeck,             // Function to set the current deck
    setIsLoading,               // Function to set loading state
    setCurrentCardIndex,        // Function to set the current card index
    setTempCard,                // Function to set the temporary card
    setUserStudying,            // Function to set study mode
    setStudySessionStartTime,
    setShowFront,               // Function to toggle front/back of card
    setRenderedLatex,           // Function to set rendered LaTeX
    setRawLatex,                // Function to set raw LaTeX
    setEditedFrontLatexContent, // Function to set edited front content
    setEditedBackLatexContent,  // Function to set edited back content
    setErrorMessage,            // Function to set error message

    // API and data management functions
    fetchDecks,                 // Function to fetch all decks from the server
    addDeck,                    // Function to add a new deck
    updateDeck,                 // Function to update an existing deck
    deleteDeck,                 // Function to delete a deck
    addCard,                    // Function to add a new card to a deck
    updateCard,                 // Function to update an existing card
    deleteCard,                 // Function to delete a card from a deck
    handleStudySessionToggle,   // Function to start or end a study session
    startFullDeckStudySession,  // Function to start a full deck study session
    updateCardRepetition,       // Function to update card repetition data
    renderLatex,                // Function to render LaTeX content
    makeAuthenticatedRequest,   // Function to make authenticated API requests

    // Utility functions
    getNextRepetitionDueDate,   // Function to calculate next repetition due date

    // Authentication state
    isAuthenticated,            // Boolean indicating if user is authenticated
  };

  // Provide the context value to the children components
  return <DeckContext.Provider value={value}>{children}</DeckContext.Provider>;
};